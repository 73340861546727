import React from 'react';
import {FlatList} from 'react-native';
import {responsiveWidth} from 'react-native-responsive-dimensions';

import {theme, reviews} from '../constants';
import {components} from '../components';
import {contacts, questions} from "../constants/constants";

const Contact: React.FC = (): JSX.Element => {
    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return <components.Header goBack={true} title='Thông tin liên hệ'/>;
    };

    const renderContent = () => {
        return (
            <FlatList
                data={contacts}
                contentContainerStyle={{
                    paddingLeft: 20,
                    marginTop: 10,
                    marginBottom: 20,
                }}
                showsHorizontalScrollIndicator={false}
                keyExtractor={(item) => item.id.toString()}
                pagingEnabled={true}
                snapToInterval={theme.sizes.width - 54}
                decelerationRate={0}
                renderItem={({item, index}) => {
                    const last = index === questions.length - 1;
                    return (
                        <components.ContactItem
                            item={item}
                            last={last}
                            containerStyle={{
                                width: responsiveWidth(100) - 40,
                                marginBottom: 14,
                            }}
                        />
                    );
                }}
            />
        );
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {renderContent()}
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default Contact;
