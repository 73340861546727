import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
// @ts-ignore
import {BASE_URL, AUTHORIZATION_TOKEN, ENDPOINTS} from '../../config';

import {
    ProductType,
    BannerType,
    CarouselType,
    TagType,
    CategoryType,
    PromocodeType,
} from '../../types';

// @ts-ignore
export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers) => {
            // @ts-ignore
            headers.set('authorization', `Bearer ${AUTHORIZATION_TOKEN}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProducts: builder.query<{ data: any }, { [key: string]: any }>({
            query: (params) => ({
                url: ENDPOINTS.get.products,
                params,
            }),
        }),
        getBanners: builder.query<{ banners: BannerType[] }, void>({
            query: () => ENDPOINTS.get.banners,
        }),
        getCarousel: builder.query<{ carousel: CarouselType[] }, void>({
            query: () => ENDPOINTS.get.carousel,
        }),
        // getCategories: builder.query<{ categories: CategoryType[] }, void>({
        //     query: () => ENDPOINTS.get.categories,
        // }),
        getCategories: builder.query<{ data: any }, { [key: string]: any }>({
            query: (params) => ({
                url: ENDPOINTS.get.categories,
                params,
            }),
        }),
        getReviews: builder.query<{ reviews: any[] }, void>({
            query: () => ENDPOINTS.get.reviews,
        }),
        getUsers: builder.query<{ users: any[] }, void>({
            query: () => ENDPOINTS.get.users,
        }),
        getTags: builder.query<{ tags: TagType[] }, void>({
            query: () => ENDPOINTS.get.tags,
        }),
        getPromocodes: builder.query<{ promocodes: PromocodeType[] }, void>({
            query: () => ENDPOINTS.get.promocodes,
        }),
        getRestaurants: builder.query<{ data: any }, void>({
            query: () => ENDPOINTS.get.restaurant,
        }),
        postOrders: builder.query({
            query: () => ENDPOINTS.post.order,
        }),
        // getOrders: builder.query<{ data: any }, void>({
        //     query: () => ENDPOINTS.get.orders,
        // }),
        getOrders: builder.query<{ data: any }, { [key: string]: any }>({
            query: (params) => ({
                url: ENDPOINTS.get.orders,
                params,
            }),
        }),
    }),
});

export const {
    useGetTagsQuery,
    useGetUsersQuery,
    useGetBannersQuery,
    useGetReviewsQuery,
    useGetProductsQuery,
    useGetCarouselQuery,
    useGetCategoriesQuery,
    useGetPromocodesQuery,
    usePostOrdersQuery,
    useGetOrdersQuery,
    useGetRestaurantsQuery,
} = apiSlice;

export default apiSlice.reducer;
