import {ENDPOINTS} from './endpoints';
import {useAppSelector} from "../hooks";

const DEBUG = true;
// const BASE_URL = 'http://127.0.0.1:8000/';
const BASE_URL = 'https://pour2go.xyz/api/v1/';
// const BASE_URL = 'https://dine-hub.rn-admin.site/';
let AUTHORIZATION_TOKEN: any = null;
// const userLogin = useAppSelector((state) => state.userSlice.user);
// console.info("===========[] ===========[userLogin] : ",userLogin);
let token = localStorage.getItem('token');
if (token) {
    let newToken = token.replace("\"", "");
    AUTHORIZATION_TOKEN = newToken;
}
const CONFIG = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + AUTHORIZATION_TOKEN,
    },
};

export {BASE_URL, AUTHORIZATION_TOKEN, ENDPOINTS, CONFIG, DEBUG};
