import React, {PropsWithChildren} from 'react';
import {View, Text} from 'react-native';
import {responsiveWidth} from 'react-native-responsive-dimensions';

import {text} from '../../text';
import {theme} from '../../constants';

type Props = PropsWithChildren<{
    item: any;
    last?: boolean;
    containerStyle?: object;
}>;

const ReviewItem: React.FC<Props> = ({
                                         item,
                                         containerStyle,
                                         last,
                                     }): JSX.Element => {
    return (
        <View
            style={{
                backgroundColor: theme.colors.white,
                width: responsiveWidth(100) - 40 - 28,
                marginRight: last ? 20 : 14,
                borderRadius: 10,
                padding: 20,
                ...containerStyle,
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 13,
                    borderBottomWidth: 1,
                    borderBottomColor: '#E9F3F6',
                    marginBottom: 11,
                }}
            >
                <View style={{marginRight: 'auto'}}>
                    <text.H5 style={{marginBottom: 4}}>{item.name}</text.H5>
                </View>
            </View>
            <text.T14>{item.comment}</text.T14>
        </View>
    );
};

export default ReviewItem;
