import React, {useState, useRef} from 'react';
import {View, ViewStyle, TextInput, ImageBackground, Text, StyleSheet, Image} from 'react-native';

import {text} from '../../text';
import {svg} from '../../assets/svg';
import {theme} from '../../constants';
import {components} from '../../components';
import {useAppDispatch, useAppNavigation, useAppSelector} from '../../hooks';
import {homeIndicatorHeight} from '../../utils';
// @ts-ignore
import SignUpImage  from '../../assets/images/sign-up.png';

import { register as registerService } from '../../store/slices/userSlice'
import Dropdown from './../../components/custom/Dropdown';

const SignUp: React.FC = (): JSX.Element => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.userSlice.user);

    const [name, setUserName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [userType, setUserType] = useState<string>('USER');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [nameError, setNameError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [alertError, setAlertError] = useState<string | null>(null);


    const validateEmail = (email: string): string | null => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            return 'Email là bắt buộc';
        } else if (!emailRegex.test(email)) {
            return 'Email không hợp lệ';
        }
        return null;
    };

    const validatePassword = (password: string): string | null => {
        if (!password) {
            return 'Mật khẩu là bắt buộc';
        } else if (password.length < 6) {
            return 'Mật khẩu phải có ít nhất 6 ký tự';
        }
        return null;
    };
    const validateRequired = (name: string): string | null => {
        if (!name) {
            return 'Dữ liệu không được để trống';
        } else if (name.length < 3) {
            return 'Dữ liệu phải có ít nhất 3 ký tự';
        }
        return null;
    };

    const inp1Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);
    const inp2Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);
    const inp3Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);
    const inp4Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);


    const renderMainText = () => {
        return <text.H1 style={{marginBottom: 30}}>Đăng ký</text.H1>;
    };

    const renderInputFields = () => {
        return (
            <React.Fragment>
                {alertError && <Text style={styles.errorText}>{alertError}</Text>}
                <components.InputField
                    type='username'
                    innerRef={inp1Ref}
                    value={name}
                    placeholder='Phú phan'
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setUserName(text)}
                />
                {nameError && <Text style={styles.errorText}>{nameError}</Text>}
                <Dropdown
                    options={[
                        { label: 'Thành viên', value: 'USER' },
                        { label: 'Chủ nhà hàng', value: 'OWNERS' }
                    ]}
                    selectedValue={userType}
                    onValueChange={setUserType}
                />
                <components.InputField
                    type='email'
                    value={email}
                    checkIcon={true}
                    innerRef={inp2Ref}
                    placeholder='doan@mail.com'
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setEmail(text)}
                />
                {emailError && <Text style={styles.errorText}>{emailError}</Text>}
                <components.InputField
                    type='password'
                    value={password}
                    eyeOffIcon={true}
                    innerRef={inp3Ref}
                    placeholder='••••••••'
                    secureTextEntry={true}
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setPassword(text)}
                />
                {passwordError && <Text style={styles.errorText}>{passwordError}</Text>}
                <components.InputField
                    type='password'
                    eyeOffIcon={true}
                    innerRef={inp4Ref}
                    value={confirmPassword}
                    placeholder='••••••••'
                    secureTextEntry={true}
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setConfirmPassword(text)}
                />
                {passwordError && <Text style={styles.errorText}>{passwordError}</Text>}
            </React.Fragment>
        );
    };

    const register = async () => {
        const emailError = validateEmail(email);
        const passwordError = validatePassword(password);
        const nameError = validateRequired(name);

        if (emailError || passwordError || nameError) {
            setEmailError(emailError);
            setPasswordError(passwordError);
            setNameError(nameError);
            return;
        }

        let data = {
            name: name,
            email: email,
            password: password,
            type: userType
        }

        // @ts-ignore
        let response = await dispatch(registerService(data))
        console.info("===========[] ===========[response] : ", response);
        if(response.payload.status === 'success'){
            if(response.payload.data.status ==  "VERIFY") {
                navigation.navigate('ConfirmationCode',{
                    email: email
                });
            }else {
                navigation.navigate('SignIn');
            }
        }

        if(response.payload.code == "F0002" && response.payload.status == "fail") {
            setAlertError(response.payload.message)
        }
    }

    const renderButton = () => {
        return (
            <components.Button
                title='Đăng ký'
                containerStyle={{marginBottom: 20}}
                onPress={() => register()}
            />
        );
    };

    const renderAlreadyHaveAccount = () => {
        return (
            <components.ParsedText
                parse={[
                    {
                        pattern: /tại đây/,
                        style: {color: theme.colors.mainTurquoise},
                        onPress: () => navigation.replace('SignIn'),
                    },
                ]}
            >
                Bạn đã có tài khoản? Đăng nhập tại đây
            </components.ParsedText>
        );
    };
    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <View style={styles.container}>
            <Image
                source={SignUpImage} // Đường dẫn đến hình nền của bạn
                style={styles.backgroundImage}
            />
            <View style={{ width: '80%'}}>
                {renderMainText()}
                {renderInputFields()}
                {renderButton()}
                {renderAlreadyHaveAccount()}
            </View>
            {renderHomeIndicator()}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 1, // Đặt opacity là 1
        zIndex: -1, // Đặt ảnh nền phía sau các thành phần khác
    },
    imageStyle: {
        opacity: 1, // Set opacity to 1 here
    },
    errorText: {
        fontSize: 12,
        color: 'red',
        marginBottom: 10,
    },
});

export default SignUp;
