import React, {useState} from 'react';
import {View, Text, FlatList, ScrollView, TouchableOpacity} from 'react-native';
import {responsiveWidth} from 'react-native-responsive-dimensions';

import {useAppDispatch, useAppSelector} from '../../hooks';
import {components} from '../../components';
import {useAppNavigation} from '../../hooks';
import {theme, reviews} from '../../constants';
import {setScreen} from '../../store/slices/tabSlice';
import BottomTabBar from '../../navigation/BottomTabBar';
import {
    useGetProductsQuery,
    useGetCarouselQuery,
    useGetCategoriesQuery,
    useGetRestaurantsQuery,
} from '../../store/slices/apiSlice';
import Image from "../../components/custom/Image";

const slides = [
    {
        image: 'https://img.freepik.com/free-vector/flat-design-vegan-food-sale-background_23-2149093229.jpg',
        id: 1
    },
    {
        image: 'https://img.freepik.com/free-vector/flat-design-vegan-food-landing-page_23-2149093223.jpg',
        id: 2
    },
]

const Home: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigation = useAppNavigation();
    const userLogin = useAppSelector((state) => state.userSlice.user);
    const {
        data: carouselData,
        error: carouselError,
        isLoading: carouselLoading,
    } = useGetCarouselQuery();

    const {
        data: categoriesData,
        error: categoriesError,
        isLoading: categoriesLoading,
    } = useGetCategoriesQuery({name: ''});

    const {
        data: productsData,
        error: productsError,
        isLoading: productsLoading,
    } = useGetProductsQuery({name: '', restaurant_id: ''});

    // @ts-ignore
    const {
        data: restaurantsData,
        error: restaurantsError,
        isLoading: restaurantsLoading,
    } = useGetRestaurantsQuery();

    const products = productsData;
    const restaurants = restaurantsData;
    const carousel = carouselData instanceof Array ? carouselData : [];
    const categories = categoriesData;

    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

    if(userLogin == null) {
        navigation.navigate('SignIn');
    }

    if(userLogin?.status == "VERIFY") {
        navigation.navigate('ConfirmationCode',{
            email: userLogin?.email
        });
    }

    const updateCurrentSlideIndex = (e: any): void => {
        const contentOffsetX = e.nativeEvent.contentOffset.x;
        const currentIndex = Math.round(contentOffsetX / theme.sizes.width);
        setCurrentSlideIndex(currentIndex);
    };

    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return (
            <components.Header
                basket={true}
                user={true}
                userImage={true}
                userName={true}
            />
        );
    };

    const renderCarousel = () => {
        const renderCarouselImages = () => {
            return (
                <FlatList
                    data={slides}
                    onMomentumScrollEnd={(e) => updateCurrentSlideIndex(e)}
                    renderItem={({item}) => (
                        <components.Image
                            source={{uri: item.image}}
                            style={{width: theme.sizes.width, height: 250, aspectRatio: 1.5}}
                        />
                    )}
                    pagingEnabled={true}
                    keyExtractor={(item: any) => item.id}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    scrollEventThrottle={16}
                    decelerationRate={0}
                    bounces={false}
                    alwaysBounceHorizontal={false}
                />
            );
        };

        const renderIndicator = () => {
            if (carousel.length > 1) {
                return (
                    <View
                        style={{
                            height: 24,
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            bottom: 20,
                            flexDirection: 'row',
                            alignSelf: 'center',
                        }}
                    >
                        {carousel.map((image, index, array) => {
                            return (
                                <View
                                    key={index}
                                    style={{
                                        width: 8,
                                        height: currentSlideIndex === index ? 20 : 8,
                                        borderRadius: 8 / 2,
                                        backgroundColor: theme.colors.white,
                                        opacity: currentSlideIndex === index ? 1 : 0.5,
                                        borderColor:
                                            currentSlideIndex === index
                                                ? theme.colors.mainColor
                                                : '#DBE9F5',
                                        marginHorizontal: 4,
                                    }}
                                />
                            );
                        })}
                    </View>
                );
            }
            return null;
        };

        if (slides.length > 0) {
            return (
                <View style={{marginBottom: 30}}>
                    {renderCarouselImages()}
                    {renderIndicator()}
                </View>
            );
        }

        if (slides.length === 0) {
            return null;
        }
    };

    const renderCategories = () => {
        // @ts-ignore
        let categoriesList: any = categories?.data?.result;
        if (categoriesList.length > 0) {
            return (
                <View style={{marginBottom: 30}}>
                    <components.BlockHeading
                        title='Chúng tôi cung cấp'
                        onPress={() => {
                            dispatch(setScreen('Menu'));
                        }}
                        containerStyle={{marginHorizontal: 20, marginBottom: 14}}
                    />
                    <FlatList
                        data={categoriesList}
                        horizontal={true}
                        contentContainerStyle={{paddingLeft: 20}}
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={(item) => item.id}
                        pagingEnabled={true}
                        decelerationRate={0}
                        renderItem={({item}) => {
                            const lastItem = categoriesList[categoriesList.length - 1];
                            return (
                                <TouchableOpacity
                                    onPress={() => {
                                        navigation.navigate('Menulist', {
                                            category: item.name,
                                            item: item
                                        });
                                    }}
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        marginRight: 10
                                    }}
                                >
                                    <Image
                                        source={{uri: item.image }}
                                        style={{
                                            width: 60,
                                            height: 60,
                                            resizeMode:'cover',
                                            borderRadius: 20 / 2,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            ...theme.fonts.DMSans_400Regular,
                                            fontSize: 15,
                                            marginTop: 10,
                                            color: theme.colors.mainColor,
                                        }}
                                    >
                                        {item.name}
                                    </Text>
                                </TouchableOpacity>
                            );
                        }}
                    />
                </View>
            );
        }

        return null;
    };

    const renderRestaurant = () => {

        if (restaurants === null || restaurants == undefined) {
            return null;
        }

        // @ts-ignore
        let restaurantsData = restaurants.data?.result;
        const slice = restaurantsData?.slice(0, 12);

        if (restaurantsData.length > 0) {
            return (
                <View style={{marginBottom: 30}}>
                    <components.BlockHeading
                        title='Nhà hàng nổi bật'
                        containerStyle={{marginHorizontal: 20, marginBottom: 14}}
                    />
                    <FlatList
                        data={slice}
                        horizontal={true}
                        contentContainerStyle={{paddingLeft: 20}}
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={(item) => item.id}
                        pagingEnabled={false}
                        snapToInterval={theme.sizes.width - responsiveWidth(44.2)}
                        decelerationRate={0}
                        renderItem={({item, index}) => {
                            const lastItem = index === slice.length - 1;
                            return (
                                <components.RestaurantItem item={item} lastItem={lastItem}/>
                            );
                        }}
                    />
                </View>
            );
        }

        if (restaurantsData.length === 0) {
            return null;
        }
    };

    const renderReviews = () => {
        const slice = reviews?.slice(0, 12);

        return (
            <View style={{marginBottom: 20}}>
                <components.BlockHeading
                    title='Our Happy clients say'
                    onPress={() => {
                        navigation.navigate('Reviews');
                    }}
                    containerStyle={{marginHorizontal: 20, marginBottom: 14}}
                />
                <FlatList
                    data={slice}
                    horizontal={true}
                    contentContainerStyle={{paddingLeft: 20}}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.id.toString()}
                    pagingEnabled={true}
                    snapToInterval={theme.sizes.width - 54}
                    decelerationRate={0}
                    renderItem={({item, index}) => {
                        const last = index === reviews.length - 1;
                        return <components.ReviewItem item={item} last={last}/>;
                    }}
                />
            </View>
        );
    };

    const renderContent = () => {
        if (carouselLoading || categoriesLoading || productsLoading) {
            return <components.Loader/>;
        }
        return (
            <ScrollView
                contentContainerStyle={{flexGrow: 1, paddingTop: 6}}
                showsVerticalScrollIndicator={false}
            >
                {renderCarousel()}
                {renderCategories()}
                {/*{renderRecommended()}*/}
                {renderRestaurant()}
                {renderReviews()}
            </ScrollView>
        );
    };

    const renderBottomTabBar = () => {
        return <BottomTabBar/>;
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {renderContent()}
            {renderBottomTabBar()}
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default Home;
