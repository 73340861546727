import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, ScrollView} from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';

import {text} from '../text';
import {theme} from '../constants';
import {components} from '../components';
import {useAppNavigation, useAppSelector} from '../hooks';
import {currencyFormat, homeIndicatorHeight as getHomeIndicatorHeight} from '../utils';
import Moment from 'moment';
import {
    useGetOrdersQuery
} from '../store/slices/apiSlice';
import Image from "../components/custom/Image";
import {addCartToDb, updateStatusOrder} from '../store/slices/cartSlice';

const OrderHistory: React.FC = (): JSX.Element => {
    const navigation = useAppNavigation();
    const [activeSections, setActiveSections] = useState<number[]>([]);
    const userLogin = useAppSelector((state) => state.userSlice.user);
    console.info("===========[] ===========[userLogin] : ",userLogin);
    // @ts-ignore
    const {
        data: orderData,
        error,
        isLoading,
        refetch,
    } = useGetOrdersQuery({
        restaurant_id : userLogin?.type == "OWNERS" ? userLogin?.restaurant?.id : '',
        user_id : userLogin?.type == "USER" ? userLogin?.id : ''
    });

    useEffect(() => {
        refetch();
    }, [refetch]);

    const homeIndicatorHeight = getHomeIndicatorHeight();

    const setSections = (sections: any) => {
        setActiveSections(sections.includes(undefined) ? [] : sections);
    };

    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return <components.Header goBack={true} title='Lịch sử đơn hàng'/>;
    };

    const cancelOrder = async(item: any) =>  {
        let orderResult = await updateStatusOrder(item.id, {
            payment_status: "CANCEL"
        })
        console.info("===========[] ===========[KQ] : ",orderResult);
        refetch();
    }

    const approvedOrder = async(item: any) => {
        let orderResult = await updateStatusOrder(item.id, {
            payment_status: "PAID"
        })
        console.info("===========[approvedOrder] ===========[item] : ",orderResult);
        refetch();
    }

    const accordionHeader = (section: any) => {
        return (
            <View
                style={{
                    marginHorizontal: 20,
                    backgroundColor: theme.colors.white,
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    paddingBottom: 17,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 7,
                    }}
                >
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <View
                            style={{
                                ...theme.fonts.DMSans_500Medium,
                                marginRight: 4,
                            }}
                        >
                            <Image
                                source={{uri: section?.restaurant?.logo}}
                                style={{
                                    width: 60,
                                    height: 60,
                                    margin: 8,
                                    borderRadius: 5
                                }}
                                imageStyle={{borderRadius: 10}}
                            />
                        </View>
                        <View style={{ display: 'flex'}}>
                            <Text
                                style={{
                                    ...theme.fonts.DMSans_400Regular,
                                    fontSize: 10,
                                    lineHeight: 10 * 1.2,
                                    marginBottom: 2,
                                    color: theme.colors.textColor,
                                }}
                            >
                                {Moment(section.created_at).format("DD-MM-YYYY")}
                            </Text>
                            <Text
                                style={{
                                    ...theme.fonts.DMSans_500Medium,
                                    fontSize: 14,
                                    lineHeight: 14 * 1.2,
                                    color: theme.colors.mainColor,
                                }}
                            >
                                {currencyFormat(section.total_price)}
                            </Text>
                            <Text
                                style={{
                                    ...theme.fonts.DMSans_500Medium,
                                    fontSize: 14,
                                    lineHeight: 14 * 1.2,
                                    color: theme.colors.mainColor,
                                }}
                            >
                                Name: {section.name}
                            </Text>

                            <Text
                                style={{
                                    ...theme.fonts.DMSans_500Medium,
                                    fontSize: 14,
                                    lineHeight: 14 * 1.2,
                                    color: theme.colors.mainColor,
                                }}
                            >
                                Name: {section.note}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <Text
                            style={{
                                ...theme.fonts.DMSans_400Regular,
                                color: theme.colors.textColor,
                                fontSize: 12,
                                height: 35,
                                lineHeight: 12 * 1.5,
                            }}
                        >
                            Order ID: {section.id}
                        </Text>
                        <View
                            style={{
                                paddingHorizontal: 0,
                                paddingVertical: 3,
                                borderRadius: 5,
                            }}
                        >
                            <Text
                                style={{
                                    ...theme.fonts.DMSans_500Medium,
                                    fontSize: 15,
                                    lineHeight: 10 * 1.5,
                                    padding:0,
                                    color: section.payment_status == 'UNPAID' ? '#FFA462' : (section.payment_status == 'CANCEL' ? '#FA5555' : '#155724'),
                                }}
                            >
                                {section.payment_status == 'UNPAID' ? 'Chưa thanh toán' :  (section.payment_status == 'CANCEL' ? 'Huỷ bỏ' : 'Đã thanh toán')}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        );
    };

    const accordionContent = (section: any) => {
        return (
            <View
                style={{
                    marginHorizontal: 20,
                    borderTopWidth: 1,
                    borderTopColor: '#DBE9F5',
                }}
            >
                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        padding: 20,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        marginBottom: 10,
                    }}
                >
                    {section.transactions.map((item: any, index: number, array: []) => {
                        const isLastItem = index === array.length - 1;
                        return (
                            <View
                                key={item.id}
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 10,
                                }}
                            >
                                <text.T14>{item.product_name}</text.T14>
                                <text.T14>
                                    {item.quantity} x ${currencyFormat(item.price)}
                                </text.T14>
                            </View>
                        );
                    })}
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <text.T14>Thành tiền</text.T14>
                        <text.T14>${currencyFormat(section.total_price)}</text.T14>
                    </View>
                    {userLogin?.type !== "USER" && (section.payment_status == "UNPAID")  &&  (
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <TouchableOpacity onPress={() => cancelOrder(section)}>
                                <Text style={{ color: '#FA5555', padding: 3, fontSize: 13, backgroundColor: 'white',
                                    borderRadius: 4}}>Huỷ bỏ</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => approvedOrder(section)}>
                                <Text style={{ color: '#FA5555', padding: 3,fontSize: 13 , backgroundColor:'white',
                                    borderRadius: 4 }}>Thanh toán</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </View>
        );
    };

    const renderContent = () => {
        // @ts-ignore
        if (orderData?.status == 'success') {
            return (
                <ScrollView contentContainerStyle={{paddingBottom: 20, flexGrow: 1}}>
                    <Accordion
                        activeSections={activeSections}
                        sections={orderData?.data?.result}
                        touchableComponent={TouchableOpacity}
                        renderHeader={accordionHeader}
                        renderContent={accordionContent}
                        duration={400}
                        onChange={setSections}
                        containerStyle={{paddingTop: 10}}
                        sectionContainerStyle={{marginBottom: 10}}
                    />
                </ScrollView>
            );
        }
    };

    const renderEmptyHistory = () => {
        if (history.length === 0) {
            return (
                <ScrollView
                    contentContainerStyle={{flexGrow: 1, paddingTop: 10}}
                    showsVerticalScrollIndicator={false}
                >
                    <View
                        style={{
                            backgroundColor: theme.colors.white,
                            flex: 1,
                            marginHorizontal: 20,
                            paddingHorizontal: 20,
                            borderRadius: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <components.Image
                            source={{uri: 'https://george-fx.github.io/dine-hub/13.jpg'}}
                            style={{
                                width: theme.sizes.width - 100,
                                aspectRatio: 1,
                                alignSelf: 'center',
                                marginBottom: 14,
                            }}
                        />
                        <text.H2
                            style={{
                                marginBottom: 14,
                            }}
                        >
                            No Order History Yet!
                        </text.H2>
                        <text.T16 style={{textAlign: 'center'}}>
                            It looks like your order history is empty.{'\n'}Place your order
                            now to start building{'\n'}your history!
                        </text.T16>
                    </View>
                </ScrollView>
            );
        }

        return null;
    };

    const renderButton = () => {
        if (history.length === 0) {
            return (
                <View
                    style={{
                        paddingHorizontal: 20,
                        paddingTop: 20,
                        marginBottom: homeIndicatorHeight === 0 ? 20 : 10,
                    }}
                >
                    <components.Button
                        title='Explore Our Menu'
                        onPress={() => {
                            navigation.navigate('VerifyYourPhoneNumber');
                        }}
                    />
                </View>
            );
        }

        return null;
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {renderContent()}
            {renderEmptyHistory()}
            {renderButton()}
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default OrderHistory;
