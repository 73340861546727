import React, {useEffect, useState} from 'react';
import {View, Text, ScrollView, TouchableOpacity} from 'react-native';

import {svg} from '../assets/svg';
import {colors, theme} from '../constants';
import {components} from '../components';
import BottomTabBar from '../navigation/BottomTabBar';
import {payments as paymentsService} from "../store/slices/userSlice";
import {currencyFormat, homeIndicatorHeight} from "../utils";
import Moment from 'moment';
import {useAppNavigation, useAppSelector} from "../hooks";
import Home from "./tabs/Home";


const Wallet: React.FC = (): JSX.Element => {
    const [payments, setPayments] = useState([]);
    const userLogin = useAppSelector((state) => state.userSlice.user);
    const navigation = useAppNavigation();

    const renderStatusBar = () => {
        return <components.StatusBar />;
    };

    const getListsPayment = async () => {
        let results = await paymentsService()
        if(results.status == 'success') {
            setPayments(results.data.result)
        }
        console.info("===========[] ===========[results] : ",results);
    }
    useEffect(() => {
        getListsPayment().then(r => {});
    }, []);

    const renderHeader = () => {
        return (
            <components.Header
                title='Ví của tôi'
                basket={true}
                user={true}
                userImage={true}
            />
        );
    };

    const renderContent = () => {
        return (
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    paddingHorizontal: 20,
                    paddingTop: 10,
                    paddingBottom: 20,
                }}
                showsVerticalScrollIndicator={false}
            >
                {payments.map((item: any, index, array) => {
                    const last = array.length - 1 === index;
                    return (
                        <TouchableOpacity
                            key={index}
                            style={{
                                width: '100%',
                                backgroundColor: theme.colors.white,
                                borderRadius: 10,
                                padding: 20,
                                marginBottom: last ? 0 : 14,
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 14,
                                }}
                            >
                                <View style={{marginRight: 8}}>
                                    <svg.CheckSvg />
                                </View>
                                <Text
                                    style={{
                                        ...theme.fonts.DMSans_500Medium,
                                        fontSize: 14,
                                        lineHeight: 14 * 1.2,
                                        color: theme.colors.mainColor,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {currencyFormat(item.pay_money)}
                                </Text>
                            </View>
                            <Text
                                style={{
                                    ...theme.fonts.DMSans_400Regular,
                                    fontSize: 14,
                                    lineHeight: 14 * 1.5,
                                    color: theme.colors.textColor,
                                    marginBottom: 14,
                                }}
                            >
                                {item.content}
                            </Text>
                            <Text
                                style={{
                                    ...theme.fonts.DMSans_400Regular,
                                    fontSize: 12,
                                    lineHeight: 12 * 1.5,
                                    color: theme.colors.textColor,
                                }}
                            >
                                {Moment(item.created_at).format("DD-MM-YYYY")}
                            </Text>
                        </TouchableOpacity>
                    );
                })}
            </ScrollView>
        );
    };

    const renderBottomTabBar = () => {
        return <BottomTabBar />;
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator />;
    };

    const funcWithdraw = () => {
        navigation.navigate("Withdraw")
    }

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            <View
                style={{
                    width: '100%',
                    borderRadius: 10,
                    marginTop: 20,
                }}
            >
                <View
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 14,
                        padding: 20,
                        margin: 20,
                        backgroundColor: theme.colors.white,
                    }}
                >
                    <Text
                        style={{
                            ...theme.fonts.DMSans_500Medium,
                            fontSize: 14,
                            lineHeight: 14 * 1.2,
                            color: theme.colors.mainColor,
                            textTransform: 'capitalize',
                        }}
                    >
                        {currencyFormat(userLogin?.wallet)}
                    </Text>
                    <View style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center"
                    }}>
                        <TouchableOpacity style={{
                            paddingHorizontal: 15,
                            marginTop: 10,
                            marginBottom: 10,
                            paddingVertical: 5,
                            borderRadius: 5,
                            backgroundColor: theme.colors.mainTurquoise,
                        }} onPress={() => funcWithdraw()}>
                            <Text style={{ color: 'white'}}>Rút tiền</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{
                            paddingHorizontal: 15,
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                            paddingVertical: 5,
                            borderRadius: 5,
                            backgroundColor: theme.colors.mainTurquoise,
                        }} onPress={() => navigation.navigate("TabNavigator")}>
                            <Text style={{ color: 'white'}}>Về Trang chủ</Text>
                        </TouchableOpacity>
                    </View>
                    <Text>Số tiền được hoàn của bạn sẽ được tự động chuyển về ví của bạn. Bạn có thể gửi yêu cầu rút tiền để được chuyển về tài khoản ngân hàng</Text>
                </View>
            </View>
            {renderContent()}
            {renderBottomTabBar()}
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default Wallet;
