import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import type {NativeStackScreenProps} from '@react-navigation/native-stack';

import {text} from '../text';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import type {RootStackParamList} from '../types';
import {addCartToDb, resetCart} from '../store/slices/cartSlice';
import {useAppSelector, useAppNavigation, useAppDispatch} from '../hooks';
// @ts-ignore
import QrCode  from '../assets/images/qr-code.jpg';
import {
    useGetProductsQuery,
    usePostOrdersQuery,
} from '../store/slices/apiSlice';
import {currencyFormat} from "../utils";

type Props = NativeStackScreenProps<RootStackParamList, 'Checkout'>;

const Checkout: React.FC<Props> = ({route}): JSX.Element => {
    const dispatch = useAppDispatch();
    const {total, subtotal, delivery, discount} = route.params;
    const navigation = useAppNavigation();
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState("");
    const cart = useAppSelector((state) => state.cartSlice.list);
    const user = useAppSelector((state) => state.userSlice.user);

    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return <components.Header goBack={true} title='Checkout'/>;
    };

    const renderOrderSummary = () => {
        return (
            <View
                style={{
                    padding: 20,
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: theme.colors.mainTurquoise,
                    marginBottom: 30,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottomWidth: 1,
                        borderBottomColor: '#DBE9F5',
                        paddingBottom: 20,
                        marginBottom: 20,
                    }}
                >
                    <text.H4>My order</text.H4>
                    <text.H4>{currencyFormat(total)}</text.H4>
                </View>
                {cart.map((item, index) => {
                    return (
                        <View
                            key={index}
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: 10,
                            }}
                        >
                            <text.T14>{item.name}</text.T14>
                            <text.T14>
                                {item.quantity} x {currencyFormat(item.price)}
                            </text.T14>
                        </View>
                    );
                })}
                {discount > 0 && (
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                        }}
                    >
                        <text.T14>Discount</text.T14>
                        <text.T14>- {discount.toFixed(2)}</text.T14>
                    </View>
                )}
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <text.T14>Mã QR thanh toán</text.T14>
                    <components.Image
                        source={{
                            uri: QrCode,
                        }}
                        style={{
                            width: 100,
                            height: 200,
                            marginHorizontal: 9,
                        }}
                    />
                </View>
                <components.InputField
                    type='text'
                    value={note}
                    checkIcon={true}
                    placeholder=''
                    onChangeText={(text) => setNote(text)}
                    containerStyle={{marginBottom: 14}}
                />

            </View>
        );
    };


    const addOrder = async () => {
        console.info("===========[] ===========[cart] : ", cart);
        console.info("===========[] ===========[user] : ", user);
        let order: any = {
            name: user?.name,
            email: user?.email,
            phone: user?.phone,
            note: note,
            address: user?.address,
            total_price: null,
            total_quantity: null,
            payment_status : "unpaid",
            status: "pending",
            user_id: user?.id
        };
        let total_price = 0;
        let total_quantity = 0;
        let transactions: { product_name: string; avatar: any; product_id: number; quantity: number; price: number; total_price: number; }[] = [];
        cart.map((item, index) => {
            console.info("===========[] ===========[item] : ",item);
            transactions.push({
                product_name: item.name,
                avatar: item.avatar,
                product_id: item.id,
                quantity: 1,
                price: item.price,
                total_price: item.price
            })
            total_price +=  item.price
            total_quantity += 1
        })
        order.total_price = total_price;
        order.total_quantity = total_quantity;
        order.transactions = transactions;
        console.info("===========[] ===========[order] : ",order);
        let orderResult = await addCartToDb(order);
        console.info("===========[] ===========[KQ] : ",orderResult);
        setNote("");
        dispatch(resetCart());
        if (orderResult.status == "success") {
            dispatch(resetCart());
            navigation.navigate('OrderSuccessful')
        }else {
            navigation.navigate('OrderFailed')
        }
    }

    const renderInputField = () => {
        return (
            <View
                style={{
                    backgroundColor: theme.colors.white,
                    paddingHorizontal: 20,
                    paddingVertical: 20,
                    borderRadius: 10,
                }}
            >
                <components.Button
                    loading={loading}
                    title='Xác nhận'
                    onPress={() => addOrder()}
                />
            </View>
        );
    };

    const renderContent = () => {
        const contentContainerStyle = {
            flexGrow: 1,
            paddingTop: 10,
            paddingBottom: 20,
            paddingHorizontal: 20,
        };

        return (
            <components.KAScrollView
                contentContainerStyle={{...contentContainerStyle}}
            >
                {renderOrderSummary()}
                {/*{renderShippingDetails()}*/}
                {/*{renderPaymentMethod()}*/}

                {renderInputField()}
            </components.KAScrollView>
        );
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {renderContent()}
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default Checkout;
