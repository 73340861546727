import * as React from 'react';
import Svg, {Path, G, Defs, ClipPath} from 'react-native-svg';

const ViewAllSvg: React.FC = () => {
  return (
    <Svg width={74} height={22} fill='none'>
      <Path
        fill='#00B0B9'
        d='M5.292 16 1.148 4.8h1.808l3.36 9.44L9.692 4.8h1.792L7.34 16H5.292Zm8.521-9.44c-.32 0-.586-.096-.8-.288a1.021 1.021 0 0 1-.304-.752.97.97 0 0 1 .304-.736c.214-.203.48-.304.8-.304.32 0 .582.101.784.304.214.192.32.437.32.736s-.106.55-.32.752c-.202.192-.464.288-.784.288ZM12.965 16V8.064h1.696V16h-1.696Zm7.647.192c-.778 0-1.472-.17-2.08-.512a3.771 3.771 0 0 1-1.408-1.456c-.341-.619-.512-1.339-.512-2.16 0-.832.166-1.563.496-2.192.341-.63.81-1.12 1.408-1.472.608-.352 1.312-.528 2.112-.528.779 0 1.456.176 2.032.528.576.341 1.024.8 1.344 1.376.32.576.48 1.21.48 1.904 0 .107-.005.224-.016.352 0 .117-.005.25-.016.4h-6.176c.053.768.304 1.355.752 1.76.459.395.987.592 1.584.592.48 0 .88-.107 1.2-.32a2.02 2.02 0 0 0 .736-.896h1.696c-.213.747-.64 1.37-1.28 1.872-.63.501-1.413.752-2.352.752Zm0-6.928c-.565 0-1.066.17-1.504.512-.437.33-.704.832-.8 1.504h4.48c-.032-.619-.25-1.11-.656-1.472-.405-.363-.912-.544-1.52-.544ZM27.515 16 25.18 8.064h1.696l1.536 5.984 1.728-5.984h1.888l1.728 5.984 1.536-5.984h1.696L34.651 16h-1.744l-1.824-6.24L29.26 16h-1.744Zm21.62.192c-.672 0-1.227-.112-1.664-.336-.437-.224-.763-.517-.976-.88a2.41 2.41 0 0 1-.32-1.216c0-.768.299-1.376.896-1.824.597-.448 1.45-.672 2.56-.672h2.08v-.144c0-.619-.17-1.083-.512-1.392-.33-.31-.763-.464-1.296-.464-.47 0-.88.117-1.232.352-.341.224-.55.56-.624 1.008h-1.696c.053-.576.245-1.067.576-1.472.341-.416.768-.73 1.28-.944a4.3 4.3 0 0 1 1.712-.336c1.11 0 1.968.293 2.576.88.608.576.912 1.365.912 2.368V16h-1.472l-.144-1.36c-.224.437-.55.805-.976 1.104-.427.299-.987.448-1.68.448Zm.336-1.376c.459 0 .843-.107 1.152-.32.32-.224.565-.517.736-.88.181-.363.293-.763.336-1.2h-1.888c-.672 0-1.152.117-1.44.352a1.106 1.106 0 0 0-.416.88c0 .363.133.65.4.864.277.203.65.304 1.12.304ZM55.385 16V4.48h1.696V16h-1.697Zm3.843 0V4.48h1.696V16h-1.696Z'
      />
      <G clipPath='url(#a)'>
        <Path
          stroke='#00B0B9'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.2}
          d='m69 15 4-4-4-4'
        />
      </G>
      <Defs>
        <ClipPath id='a'>
          <Path fill='#fff' d='M68 6h6v10h-6z' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ViewAllSvg;
