import React, {useState} from 'react';
import {View, Text, ViewStyle, TextStyle, TouchableOpacity, ImageBackground, StyleSheet} from 'react-native';

import {text} from '../../text';
import {svg} from '../../assets/svg';
import {theme} from '../../constants';
import {components} from '../../components';
import {useAppDispatch, useAppNavigation} from '../../hooks';
import {homeIndicatorHeight} from '../../utils';
import {login as loginService} from "../../store/slices/userSlice";
// @ts-ignore
import SignUpImage  from '../../assets/images/sign-up.png';

const SignIn: React.FC = (): JSX.Element => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [alertError, setAlertError] = useState<string | null>(null);
    const validateEmail = (email: string): string | null => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            return 'Email là bắt buộc';
        } else if (!emailRegex.test(email)) {
            return 'Email không hợp lệ';
        }
        return null;
    };

    const validatePassword = (password: string): string | null => {
        if (!password) {
            return 'Mật khẩu là bắt buộc';
        } else if (password.length < 6) {
            return 'Mật khẩu phải có ít nhất 6 ký tự';
        }
        return null;
    };

    const renderWelcome = () => {
        return <text.H1 style={{marginBottom: 14}}>Chào mừng bạn trở lại!</text.H1>;
    };

    const renderDescription = () => {
        return <text.T16 style={{marginBottom: 30}}>Đăng nhập để tiếp tục</text.T16>;
    };

    const renderInputFields = () => {
        return (
            <React.Fragment>
                {alertError && <Text style={styles.errorText}>{alertError}</Text>}
                <components.InputField
                    type='email'
                    value={email}
                    checkIcon={true}
                    placeholder='doan@mail.com'
                    onChangeText={(text) => setEmail(text)}
                    containerStyle={{marginBottom: 14}}
                />
                {emailError && <Text style={styles.errorText}>{emailError}</Text>}
                <components.InputField
                    type='password'
                    value={password}
                    eyeOffIcon={true}
                    secureTextEntry={true}
                    placeholder='••••••••'
                    onChangeText={(text) => setPassword(text)}
                    containerStyle={{marginBottom: 20}}
                />
                {passwordError && <Text style={styles.errorText}>{passwordError}</Text>}
            </React.Fragment>
        );
    };

    const renderForgotPassword = () => {
        const textStyles: TextStyle = {
            ...theme.fonts.textStyle_14,
            color: theme.colors.mainTurquoise,
        };

        return (
            <Text
                onPress={() => navigation.navigate('ForgotPassword')}
                style={{...textStyles}}
            >
                Quên mật khẩu?
            </Text>
        );
    };

    const renderRememberMe = () => {
        return (
            <TouchableOpacity
                style={{flexDirection: 'row', alignItems: 'center'}}
                onPress={() => setRememberMe(!rememberMe)}
            >
                <View
                    style={{
                        width: 18,
                        height: 18,
                        backgroundColor: '#E6EFF8',
                        borderRadius: 4,
                        marginRight: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {rememberMe && (
                        <View
                            style={{
                                width: 10,
                                height: 10,
                                borderRadius: 2,
                                backgroundColor: theme.colors.mainTurquoise,
                            }}
                        />
                    )}
                </View>
                <text.T14>Remember me</text.T14>
            </TouchableOpacity>
        );
    };

    const renderAdditionalButtons = () => {
        const containerStyle: ViewStyle = {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 40,
        };

        return (
            <View style={{...containerStyle}}>
                {renderRememberMe()}
                {renderForgotPassword()}
            </View>
        );
    };

    const login = async () => {
        const emailError = validateEmail(email);
        const passwordError = validatePassword(password);

        if (emailError || passwordError) {
            setEmailError(emailError);
            setPasswordError(passwordError);
            return;
        }

        let data = {
            username: email,
            password: password
        };

        // @ts-ignore
        let response = await dispatch(loginService(data))
        console.info("===========[] ===========[response.payload.data] : ",response);
        if(response.payload.status === 'success') {
            localStorage.setItem('token', response.payload.data.token_info.access_token);
            navigation.navigate('TabNavigator');
        }else{
            if(response.payload.code == "LG0002" && response.payload.status == "fail") {
                setAlertError(response.payload.message)
            }
            if(response.payload.code == "LG0003" && response.payload.status == "fail") {
                setAlertError(response.payload.message)
            }
            if(response.payload.code == "LG0004" && response.payload.status == "fail") {
                navigation.navigate('ConfirmationCode',{
                    email: email
                });
            }
        }
    }

    const renderButton = () => {
        return (
            <components.Button
                title='Đăng nhập'
                containerStyle={{marginBottom: 20}}
                onPress={() => login()}
            />
        );
    };

    const renderDonTHaveAccount = () => {
        return (
            <components.ParsedText
                parse={[
                    {
                        pattern: /tại đây/,
                        style: {color: theme.colors.mainTurquoise},
                        onPress: () => navigation.navigate('SignUp'),
                    },
                ]}
            >
                Bạn chưa có tài khoản? Đăng ký tại đây.
            </components.ParsedText>
        );
    };

    const renderFooter = () => {
        const styles: ViewStyle = {
            backgroundColor: theme.colors.white,
            width: '48%',
            height: 50,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
        };

        const containerStyle: ViewStyle = {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 20,
            justifyContent: 'space-between',
            marginTop: 10,
            marginBottom: homeIndicatorHeight() === 0 ? 20 : 10,
        };

        return (
            <View style={{...containerStyle}}>
                <View style={{...styles}}>
                    <svg.FacebookSvg/>
                </View>
                <View style={{...styles}}>
                    <svg.GoogleSvg/>
                </View>
            </View>
        );
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <ImageBackground
            source={SignUpImage}
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
            }}
            imageStyle={styles.imageStyle}
            resizeMode="cover" // Điều chỉnh cách hiển thị ảnh nền
        >
            <View style={{ width: '80%'}}>
                {renderWelcome()}
                {renderDescription()}
                {renderInputFields()}
                {renderAdditionalButtons()}
                {renderButton()}
                {renderDonTHaveAccount()}
            </View>
            {/*{renderFooter()}*/}
            {renderHomeIndicator()}
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    imageBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    imageStyle: {
        opacity: 1, // Set opacity to 1 here
    },
    errorText: {
        fontSize: 12,
        color: 'red',
        marginBottom: 10,
    },
});

export default SignIn;
