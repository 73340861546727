import React, {PropsWithChildren} from 'react';
import {View, TouchableOpacity} from 'react-native';
import {responsiveWidth} from 'react-native-responsive-dimensions';

import {theme} from '../../constants';
import {useAppNavigation} from '../../hooks';
import ProductName from '../product/ProductName';
import ImageBackground from '../custom/ImageBackground';
import ProductInWishlist from '../product/ProductInWishlist';

type Props = PropsWithChildren<{
    item: any;
    lastItem?: boolean;
}>;

const RestaurantItem: React.FC<Props> = ({item, lastItem}): JSX.Element => {
    const navigation = useAppNavigation();
    return (
        <TouchableOpacity
            style={{
                width: responsiveWidth(50),
                backgroundColor: theme.colors.white,
                marginRight: lastItem ? 20 : 14,
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onPress={() => {
                navigation.navigate('RestaurantsDetail', {item});
            }}
        >
            <ImageBackground
                source={{uri: item.logo}}
                style={{
                    width: '100%',
                    aspectRatio: 1,
                }}
                imageStyle={{
                    borderRadius: 10,
                }}
            >
                <ProductInWishlist
                    item={item}
                    containerStyle={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        padding: 14,
                    }}
                />
            </ImageBackground>
            <View style={{paddingHorizontal: 14, paddingBottom: 14}}>
                <ProductName
                    item={item}
                    style={{color: theme.colors.textColor, marginBottom: 3, marginTop: 10, fontWeight: 'bold'}}
                />
            </View>
        </TouchableOpacity>
    );
};

export default RestaurantItem;
