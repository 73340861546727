import Wallet from './Wallet';
import Reviews from './Reviews';
import Questions from './Questions';
import Contact from './Contact';
import Product from './Product';
import Menulist from './Menulist';
import RestaurantsDetail from './RestaurantsDetail';
import Checkout from './Checkout';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import Promocodes from './Promocodes';
import Onboarding from './Onboarding';
import AddANewCard from './AddANewCard';
import OrderFailed from './OrderFailed';
import EditProfile from './EditProfile';
import OrderHistory from './OrderHistory';
import LeaveAReview from './LeaveAReview';
import NewPassword from './auth/NewPassword';
import TrackYourOrder from './TrackYourOrder';
import OrderSuccessful from './OrderSuccessful';
import ForgotPassword from './auth/ForgotPassword';
import ConfirmationCode from './auth/ConfirmationCode';
import SignUpaccountCreated from './auth/SignUpaccountCreated';
import VerifyYourPhoneNumber from './auth/VerifyYourPhoneNumber';
import ForgotPasswordSentEmail from './auth/ForgotPasswordSentEmail';

// Tabs
import Home from './tabs/Home';
import Order from './tabs/Order';
import Menu from './tabs/Menu';
import Favorite from './tabs/Favorite';
import Notification from './tabs/Notification';
import Withdraw from "./Withdraw";

export const screens = {
    Home,
    Menu,
    Order,
    SignIn,
    Wallet,
    SignUp,
    Product,
    Reviews,
    Questions,
    Contact,
    Checkout,
    Favorite,
    Menulist,
    RestaurantsDetail,
    Promocodes,
    Onboarding,
    OrderFailed,
    NewPassword,
    AddANewCard,
    Withdraw,
    EditProfile,
    LeaveAReview,
    OrderHistory,
    Notification,
    TrackYourOrder,
    ForgotPassword,
    OrderSuccessful,
    ConfirmationCode,
    SignUpaccountCreated,
    VerifyYourPhoneNumber,
    ForgotPasswordSentEmail,
};
