export const ENDPOINTS = {
    get: {
        tags: 'api/tags',
        users: 'api/users',
        orders: 'order/list',
        carousel: 'slide/list',
        banners: 'api/banners',
        reviews: 'api/reviews',
        discount: 'api/discount',
        products: 'product/list',
        promocode: 'api/promocode',
        promocodes: 'api/promocodes',
        categories: 'category/list',
        restaurant: 'restaurant/list'
    },
    post: {
        order: 'order/create',
    },
    auth: {
        login: 'api/auth/login',
        updateUser: 'api/auth/user/update',
        emailVerify: 'api/auth/email/verify',
        createNewUser: 'api/auth/user/create',
        ifUserExists: 'api/auth/user/exists',
        ifEmailExists: 'api/auth/email/exists',
        emailConfirm: 'api/auth/email/confirm',
    },
};
