import {AppStateType} from '../../types';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from "axios";
import {AUTHORIZATION_TOKEN, BASE_URL} from '../../config';

import header from "../../components/Header";

const initialState: AppStateType = {user: null, token: null};

export const register = createAsyncThunk(
    'auth/register',
    async (data) => {
        const response = await axios.post(
            `${BASE_URL}auth/register`,
            data,
        );
        console.info("===========[api] ===========[register] : ",response);
        return response.data;
    },
);

export const login = createAsyncThunk(
    'auth/login',
    async (data) => {
        const response = await axios.post(
            `${BASE_URL}auth/login`,
            data,
        );
        console.info("===========[api] ===========[login] : ",response);
        return response.data;
    },
);

export const verifyOtp = createAsyncThunk(
    'auth/verify-otp',
    async (data: any) => {
        const response = await axios.post(
            `${BASE_URL}auth/verify-otp`,
            data,
        );
        console.info("===========[api] ===========[verify-otp] : ",response);
        return response.data;
    },
);

export const resetPassword = createAsyncThunk(
    'auth/reset-password',
    async (data: any) => {
        const response = await axios.post(
            `${BASE_URL}auth/reset-password`,
            data,
        );
        console.info("===========[api] ===========[resetPassword] : ",response);
        return response.data;
    },
);

export const payments = async () => {
    const response = await axios.get(
        `${BASE_URL}payment/list`,{
            headers: {
                'Authorization': `Bearer ${AUTHORIZATION_TOKEN}`
            }
        }
    );
    console.info("===========[api] ===========[payments] : ",response);
    return response.data;
};

export const paymentsCreate = createAsyncThunk(
    'payment/store',
    async (data: any) => {
        const response = await axios.post(
            `${BASE_URL}payment/store`,
            data,{
                headers: {
                    'Authorization': `Bearer ${AUTHORIZATION_TOKEN}`
                }
            }
        );
        console.info("===========[api] ===========[paymentsCreate] : ",response);
        return response.data;
    },
);


export const updateProfile = createAsyncThunk(
    'auth/profile',
    async (data) => {
        const response = await axios.put(
            `${BASE_URL}auth/profile`,
            data,{
                headers: {
                    'Authorization': `Bearer ${AUTHORIZATION_TOKEN}`
                }
            }
        );
        return response.data;
    },
);

const userSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AppStateType['user']>) => {
            state.user = action.payload;
        },
        logoutState: (state) => {
            state.user = null;
            state.token = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(register.fulfilled, (state, action) => {
            // state.initialState.push(action.payload)
            if(action.payload.status === 'success') {
                state.user = action.payload.data;
            }
        }),
        builder.addCase(login.fulfilled, (state, action) => {

            if(action.payload.status === 'success') {
                state.user = action.payload.data.user;
                state.token = action.payload.data.token_info.access_token;
            }
        }),
        builder.addCase(verifyOtp.fulfilled, (state, action) => {
            if(action.payload.status === 'success') {
                state.user = action.payload.data.user;
                state.token = action.payload.data.token_info.access_token;
            }
        }),
        builder.addCase(updateProfile.fulfilled, (state, action) => {

            if(action.payload.status === 'success') {
                state.user = action.payload.data;
            }
        })
    },
});

export const {setUser, logoutState} = userSlice.actions;

export {userSlice};
