const reviews = [
  {
    id: 1,
    name: 'Sarah Johnson',
    image: 'https://george-fx.github.io/dine-hub/reviews/01.jpg',
    comment:
      'Absolutely loved the food! The flavors were so vibrant and the presentation was top-notch. Can not wait to order again!',
    rating: 5,
    date: 'Aug 23, 2023',
  },
  {
    id: 2,
    name: 'Michael Chen',
    image: 'https://george-fx.github.io/dine-hub/reviews/02.jpg',
    comment:
      'The vegetable salad I ordered was so fresh and delicious. This restaurant is now my go-to for healthy meals.',
    rating: 5,
    date: 'Aug 23, 2023',
  },
  {
    id: 3,
    name: 'Emily Davis',
    image: 'https://george-fx.github.io/dine-hub/reviews/03.jpg',
    comment:
      'The quality of the ingredients shines through in every bite. This is definitely my new favorite spot.',
    rating: 5,
    date: 'Aug 23, 2023',
  },
  {
    id: 4,
    name: 'Daniel Martinez',
    image: 'https://george-fx.github.io/dine-hub/reviews/04.jpg',
    comment:
      'I can not stop raving about the amazing food I had here. It is a culinary delight that everyone should experience.',
    rating: 5,
    date: 'Aug 23, 2023',
  },
];

const questions = [
  {
    id: 1,
    name: 'Khiếu nại như thế nào',
    comment:
      'Gủi liên hệ tới admin, hoạc qua số hotline của chúng tôi!',
  },
  {
    id: 2,
    name: 'Chính sách đổi trả',
    comment:
      'Sản phẩm nguyên giá & giảm giá đến 30% được đổi size/màu hoặc sản phẩm khác (nếu còn hàng), áp dụng 1 đổi 1, đổi 1 lần/1 đơn hàng. Tổng giá trị các mặt hàng muốn đổi phải có giá trị tương đương hoặc lớn hơn với mặt hàng trả lại. PANTIO không hoàn lại tiền thừa trong trường hợp sản phẩm mới có giá trị thấp hơn sản phẩm đã mua',
  },
];

const contacts = [
  {
    id: 1,
    name: 'Email',
    comment: 'pour2go@gmail.com',
  },
  {
    id: 2,
    name: 'Số điện thoại',
    comment: '0966-608-252',
  },
  {
    id: 3,
    name: 'Facebook',
    comment: 'facebook.com/pour2go',
  },
  {
    id: 4,
    name: 'Instagram',
    comment: 'instagram.com/pour2go',
  },
];

export {reviews, questions, contacts};
