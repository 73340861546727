import React, {useState} from 'react';
import {View, Text, ViewStyle} from 'react-native';

import {theme} from '../../constants';
import {components} from '../../components';
import {useAppDispatch, useAppNavigation} from '../../hooks';
import {resetPassword as resetPasswordService} from "../../store/slices/userSlice";
const ForgotPassword: React.FC = (): JSX.Element => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>('');

    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return <components.Header goBack={true} title='Quên mật khẩu'/>;
    };

    const renderDescription = () => {
        const descriptionStyle = {
            ...theme.fonts.DMSans_400Regular,
            fontSize: 16,
            lineHeight: 16 * 1.7,
            color: theme.colors.textColor,
            marginBottom: 30,
        };

        return (
            <Text style={{...descriptionStyle}}>
                Xin vui lòng điền email đăng ký tài khoản, mã xác nhận sẽ được gủi về email của bạn.
            </Text>
        );
    };

    const renderInputField = () => {
        return (
            <React.Fragment>
                <components.InputField
                    type='email'
                    value={email}
                    placeholder='jordanhebert@mail.com'
                    containerStyle={{marginBottom: 20}}
                    onChangeText={(text) => setEmail(text)}
                />
            </React.Fragment>
        );
    };

    const renderContent = () => {
        const styles: ViewStyle = {
            flexGrow: 1,
            paddingHorizontal: 20,
            borderTopEndRadius: 10,
            borderTopStartRadius: 10,
            marginTop: 10,
        };

        const blockStyle = {
            backgroundColor: theme.colors.white,
            paddingVertical: 30,
            paddingHorizontal: 20,
            borderRadius: 10,
        };

        return (
            <components.KAScrollView contentContainerStyle={{...styles}}>
                <View style={{...blockStyle}}>
                    {renderDescription()}
                    {renderInputField()}
                    {renderButton()}
                </View>
            </components.KAScrollView>
        );
    };

    const resendEmail = async() => {
        // navigation.navigate('NewPassword');
        let response = await dispatch(resetPasswordService({
            email: email
        }))
        console.info("===========[] ===========[response.payload.data] : ",response);
        if(response.payload.status === 'success') {
            // navigation.navigate('TabNavigator');
        }else{
            // if(response.payload.code == "LG0004" && response.payload.status == "fail") {
            //     navigation.navigate('ConfirmationCode',{
            //         email: email
            //     });
            // }
        }
        console.info("===========[] ===========[email] : ",email);
    }

    const renderButton = () => {
        return (
            <components.Button
                title='send'
                onPress={() => resendEmail()}
            />
        );
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {renderContent()}
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default ForgotPassword;
