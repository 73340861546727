import React, {useState, useRef} from 'react';
import {View, ViewStyle, TextInput} from 'react-native';

import {text} from '../text';
import {svg} from '../assets/svg';
import {theme} from '../constants';
import {components} from '../components';
import {useAppDispatch, useAppNavigation, useAppSelector} from '../hooks';
import {homeIndicatorHeight} from '../utils';
import { paymentsCreate } from '../store/slices/userSlice'

const banks = [
    {
        id: 1,
        name: "VPBANK"
    },
    {
        id: 2,
        name: "Vietcombank"
    },
]
const Withdraw: React.FC = (): JSX.Element => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.userSlice.user);

    const [name, setUserName] = useState<string>('');
    const [bankName, setBankName] = useState<string>('');
    const [bankNumber, setBankNumber] = useState<string>('');
    const [price, setPrice] = useState<string>('');

    const inp1Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);
    const inp2Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);
    const inp3Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);
    const inp4Ref = useRef<TextInput>({
        focus: () => {
        }
    } as TextInput);

    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return <components.Header goBack={true}/>;
    };

    const renderMainText = () => {
        return <text.H1 style={{marginBottom: 30}}>Rút tiền</text.H1>;
    };

    const renderInputFields = () => {
        return (
            <React.Fragment>
                <components.InputField
                    type='name'
                    innerRef={inp1Ref}
                    value={name}
                    placeholder='Chủ tài khoản'
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setUserName(text)}
                />
                <components.InputField
                    type='bankName'
                    innerRef={inp1Ref}
                    value={bankName}
                    placeholder='Ngân hàng'
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setBankName(text)}
                />
                <components.InputField
                    type='username'
                    innerRef={inp1Ref}
                    value={bankNumber}
                    placeholder='Số tài khoản'
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setBankNumber(text)}
                />
                <components.InputField
                    type='username'
                    innerRef={inp1Ref}
                    value={price}
                    placeholder='Số tiền rút'
                    containerStyle={{marginBottom: 14}}
                    onChangeText={(text) => setPrice(text)}
                />
            </React.Fragment>
        );
    };

    const register = async () => {
        let data = {
            name: name,
            title : "Rút tiền",
            content: ` STK ` + bankNumber + ` Ngân hàng: ` + bankName + ` Chủ TK: ` + bankName,
            pay_money: price,
            type: "PAY_OUT",
            status: "COMPLETED",
            user_id: user?.id
        }
        console.info("===========[] ===========[data] : ", data);
        // // @ts-ignore
        let response = await dispatch(paymentsCreate(data))
        console.info("===========[] ===========[response] : ", response);
        if(response.payload.status === 'success'){
            navigation.navigate('Wallet');
        }

    }

    const renderButton = () => {
        return (
            <components.Button
                title='Xác nhận'
                containerStyle={{marginBottom: 20}}
                onPress={() => register()}
            />
        );
    };


    const renderContent = () => {
        const styles: ViewStyle = {
            backgroundColor: theme.colors.white,
            paddingHorizontal: 20,
            marginHorizontal: 20,
            borderTopEndRadius: 10,
            borderTopStartRadius: 10,
            justifyContent: 'center',
            marginTop: 20,
        };

        return (
            <components.KAScrollView contentContainerStyle={{...styles}}>
                {renderMainText()}
                {renderInputFields()}
                {renderButton()}
            </components.KAScrollView>
        );
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {/*{renderContent()}*/}
            <View style={{
                padding: 20,
                backgroundColor: 'white'
            }}>
                {renderMainText()}
                {renderInputFields()}
                {renderButton()}
            </View>
            {renderHomeIndicator()}
        </components.SmartView>
    );
};

export default Withdraw;
