import React, {useState} from 'react';
import type {NativeStackScreenProps} from '@react-navigation/native-stack';
import {
    View,
    TextInput,
    TouchableOpacity,
    FlatList,
    ScrollView,
    StyleSheet
} from 'react-native';

import {text} from '../text';
import {theme} from '../constants';
import {components} from '../components';
import type {RootStackParamList} from '../types';
import {
    useGetProductsQuery,
    useGetCategoriesQuery,
} from '../store/slices/apiSlice';
import { A } from '@expo/html-elements';

type Props = NativeStackScreenProps<RootStackParamList, 'RestaurantsDetail'>;

const RestaurantsDetail: React.FC<Props> = ({route}): JSX.Element => {
    const {item} = route.params;
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(item.category_ids || '');
    const [textSearch, setTextSearch] = useState('');

    console.info("===========[] ===========[RestaurantsDetail.item] : ",item);
    const {
        data: productsData,
        error: productsError,
        isLoading: productsLoading,
    } = useGetProductsQuery({name: textSearch, restaurant_id: item?.id});

    const {
        data: categoriesData,
        error: categoriesError,
        isLoading: categoriesLoading,
    } = useGetCategoriesQuery({name: ''});

    const products = productsData;
    const categories = categoriesData;

    if (loading) {
        return <components.Loader/>;
    }

    const renderStatusBar = () => {
        return <components.StatusBar/>;
    };

    const renderHeader = () => {
        return <components.Header goBack={true} title='Chi tiết nhà hàng' basket={true}/>;
    };

    const renderSearchBar = () => {
        return (
            <View
                style={{
                    marginTop: 10,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginHorizontal: 20,
                    height: 50,
                    marginBottom: 14,
                }}
            >
                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        flex: 1,
                        borderRadius: 10,
                        marginRight: 5,
                        padding: 5,
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <TextInput
                        placeholder='Search ...'
                        value={textSearch}
                        onChangeText={setTextSearch}
                        style={{
                            flex: 1,
                            ...theme.fonts.DMSans_400Regular,
                            fontSize: 16,
                            color: theme.colors.mainColor,
                        }}
                        placeholderTextColor={theme.colors.textColor}
                    />
                </View>
            </View>
        );
    };

    const renderCategories = () => {
        let categoriesList: any = {};
        if (item.categories) {
            categoriesList = item?.categories;
        }else {
            // @ts-ignore
            categoriesList = categories?.data?.result;
        }
        return (
            <FlatList
                data={categoriesList}
                horizontal={true}
                decelerationRate={0}
                contentContainerStyle={{
                    paddingLeft: 20,
                    marginBottom: 20,
                }}
                style={{
                    flexGrow: 0,
                }}
                showsHorizontalScrollIndicator={false}
                renderItem={({item, index}) => {
                    const last = index === categoriesList.length - 1;
                    return (
                        <TouchableOpacity
                            style={{
                                paddingHorizontal: 20,
                                paddingVertical: 10,
                                backgroundColor: theme.colors.white,
                                borderRadius: 10,
                                marginRight: last ? 20 : 8,
                                borderWidth: 1,
                                borderColor:
                                    selectedCategory === item.id
                                        ? theme.colors.mainTurquoise
                                        : theme.colors.white,
                            }}
                            onPress={() => {
                                setSelectedCategory(item.id);
                            }}
                        >
                            <text.H5
                                style={{
                                    color:
                                        selectedCategory === item.id
                                            ? theme.colors.mainTurquoise
                                            : theme.colors.mainColor,
                                }}
                            >
                                {item.name}
                            </text.H5>
                        </TouchableOpacity>
                    );
                }}
            />
        );
    };

    const renderContent = () => {
        console.info("===========[] ===========[productsData] : ",productsData);
        if(productsData == null) {
            return null;
        }
        // @ts-ignore
        let productsLists = productsData.data?.result;
        let arrIds : any = {};
        console.info("===========[] ===========[selectedCategory] : ",selectedCategory);
        const dishesByCategory = productsLists?.filter((product: any) => {
            if(selectedCategory)
                arrIds = selectedCategory.toString().split(',');
            if (arrIds.includes(product.category_id.toString())) return product;
        });
        return (
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    paddingHorizontal: 20,
                }}
                showsVerticalScrollIndicator={false}
            >
                {dishesByCategory?.map((item: any, index: any, array: any) => {
                    const lastItem = index === array.length - 1;
                    return (
                        <components.MenuListItem
                            item={item}
                            lastItem={lastItem}
                            key={item.id}
                        />
                    );
                })}
            </ScrollView>
        );
    };

    const renderHomeIndicator = () => {
        return <components.HomeIndicator/>;
    };

    return (
        <components.SmartView>
            {renderStatusBar()}
            {renderHeader()}
            {renderSearchBar()}
            {renderCategories()}

            { item?.map && (
                <View
                    style={{
                        marginTop: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginHorizontal: 20,
                        height: 50,
                        marginBottom: 14,
                    }}
                >
                    <View
                        style={{
                            backgroundColor: theme.colors.white,
                            flex: 1,
                            borderRadius: 10,
                            marginRight: 5,
                            padding: 5,
                            paddingLeft: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <A href={`${item?.map}`}>Xem địa chỉ nhà hàng</A>
                    </View>
                </View>
            )}


            {renderContent()}

            {renderHomeIndicator()}
        </components.SmartView>
    );
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    webview: {
        flex: 1,
    },
});
export default RestaurantsDetail;
